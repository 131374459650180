import ReactDOM from 'react-dom';
import './App.css'; // Import your CSS file for the modal styles
import React, { useState, useEffect } from 'react';


interface ModalSameCardsProps {
  assetName: string;
  onClose: () => void;
  refresh: number; // Add refresh prop
  activeSection: string;
}

const ModalSameCards: React.FC<ModalSameCardsProps> = ({ assetName, onClose, refresh, activeSection }) => {

  useEffect(() => {
    // Prevent body scroll when modal is open
    document.body.style.overflow = 'hidden';
    return () => {
      // Restore body scroll when modal is closed
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="modalCards" key={refresh}> {/* Add key to force re-render */}
  
            <button 
              className="close-button-modal1" 
              onClick={() => {onClose()}}>
             <i className="fa-regular fa-circle-xmark"></i>
            </button>

      <div className="smoothModal">
      <div id="tokenContainerAllAuctions">
        
      <div className="tokens-listing-team" id="tokensteamshortname"></div> 
      </div>
      </div>
    </div>
  );
};

export default ModalSameCards;



